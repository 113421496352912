import { ArrowLeftIcon, ChatBubbleBottomCenterIcon, Cog6ToothIcon } from "@heroicons/react/24/outline";
import LogoImg from "../../assets/img/logo.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSupport } from "../../store/support";

const Support = () => {
  const navigate = useNavigate();
  const [subjects, setSubjects] = useState<any[]>([]);
  const [displayForm, setDisplayForm] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const { createDiscussion, getDiscussions } = useSupport();



  useEffect(() => {
    loadDiscussions();
  }, []);

  const loadDiscussions = async () => {
    const data = await getDiscussions();
    setSubjects(data.reverse());
  };

  const onStart = async () => {
    await createDiscussion(subject, message);
    await loadDiscussions();
    const newSubject = subjects[0] 
    navigate('/dashboard/support/' + newSubject.id)
  };

  const goBack = () => {
    navigate("/dashboard/profile");
  };

  const goToDiscussion = (id: string) => {
    navigate("/dashboard/support/" + id)
  }

  

  return (
    <>
      {/* Details */}
      {displayForm ? (
        <div className="absolute w-full h-screen bg-black bg-opacity-70  z-40 flex flex-col justify-end">
          <div className="mx-auto bg-white bg-opacity-100 w-full rounded-t-2xl py-16 z-50 px-4 space-y-8">
            <h2 className="text-center text-lg font-bold">Faites-nous part de votre problème</h2>

            <input
              onChange={($event) => setSubject($event.target.value)}
              type="text"
              placeholder="Sujet"
              className="w-full p-4  text-black outline-none rounded-l-full rounded-r-full  text-md  border-2 border-primary"
            />

            <textarea
              onChange={($event) => setMessage($event.target.value)}
              className="w-full  text-black outline-none rounded-l-full rounded-r-full p-4 text-md  border-2 border-primary"
            placeholder="Entrer votre message ici..."
           >
            </textarea>

            <button
              disabled={!subject || !message}
              onClick={onStart}
              className="bg-secondary px-16 py-2 rounded-l-full rounded-r-full text-dark w-full"
            >
              Envoyer
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="w-full h-full  bg-white p-8">
        <div className="h-16 w-full  bg-white p-1 flex justify-between items-center">
        <img src={LogoImg} alt="logo" className="w-16 h-16 rounded-full" />

          <h3>Messages</h3>
          <button className="rounded-2xl w-12 h-12 flex justify-center items-center border aspect-square">
            <Cog6ToothIcon className="w-8" />
          </button>
        </div>

        <div className="w-full my-8">
          {/* <button
            onClick={() => setDisplayForm(true)}
            className="bg-tertiary w-full py-1 rounded-lg text-white"
          >
            Nouvelle discussion
          </button> */}

          {subjects.map((subject) => (
            <div
              key={subject.id}
              onClick={() => goToDiscussion(subject.id)}
              className="w-full bg-white shadow-sm p-2 flex items-center justify-between rounded-xl gap-2"
            >
              <div className="p-2 flex gap-2 items-center">
                <ChatBubbleBottomCenterIcon className="w-8 h-8 mx-auto text-primary" />
                <div>
                  <h3 className="text-xs text-primary">{subject.subject}</h3>
                  <p className="text-sm text-gray-400">
                     {subject.status}
                  </p>
                </div>
              </div>
              <div>
                <p className="text-xs text-gray-500">
                  {subject.date} à {subject.time}
                </p>
                <span className="text-sm text-primary">
                  {+subject.unread} non lu
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default Support;
