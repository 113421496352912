import { AccountType } from "../models";
import { useApi } from "./api";

export const useOperation = () => {
  const { $post, $get } = useApi();
  const readQrCode = async (code: string) => {
    const res = await $post("qrcode/read_account_qr/", { code }, false);
    console.log(res);
    return res;
  };

  const readPseudo = async (pseudo: string) => {
    const res = await $post("transaction/get_receiver_account_id/", { receiver_username: pseudo });
    // console.log(res);
    return res;
  };

  const readPhone = async (receiver_id: string, receiver_type: AccountType) => {
    const res = await $post("transaction/get_receiver_account_id/", { receiver_id, receiver_type });
    // console.log(res);
    return res;
  };

  const getReceiver = async (data: {
    contact_id?: string;
    receiver_id?: string;
    receiver_type?: string;
    receiver_username?: string;
    qr_code?: string;
  }) => {
    console.log(data);
    const res = await $post("transaction/get_receiver_account_id/", data, false, true);
    // console.log(res);
    return res;
  };

  const deposit = async (
    receiver_type: AccountType,
    receiver_id: string,
    amount: number,
    password: string
  ) => {
    return await $post(
      "transaction/recharge/",
      { receiver_type, receiver_id, amount, password },
      false
    );
  };

  const transfert = async (
    receiver_type: AccountType,
    receiver_id: string,
    amount: number,
    password: string
  ) => {
    return await $post(
      "transaction/transfert/",
      { receiver_type, receiver_id, amount, password },
      false
    );
  };

  const pay = async (
    receiver_type: AccountType,
    receiver_id: string,
    amount: number,
    password: string,
    invoice_code?: string
  ) => {
    const data: any = { receiver_type, receiver_id, amount, password };
    if (invoice_code) data.invoice_code = invoice_code;
    return await $post("transaction/payment/", data, false);
  };

  const payInvoice = async (password: string, invoice_code?: string) => {
    const res = await $post("transaction/payment/", { password, invoice_code }, false);
    console.log(res);
    return res;
  };

  const withdraw = async (
    receiver_type: AccountType,
    receiver_id: string,
    amount: number,
    password: string
  ) => {
    return await $post(
      "retrait/",
      { receiver_type, receiver_id, amount, password },
      false
    );
  };

  const getOperations = async (req: {
    start_date?: string;
    end_date?: string;
    year?: string;
    month?: string;
    day?: string;
  }) => {
    const data: any = {};
    const keys: any = req;
    const params = Object.keys(keys).filter((key) => {
      if (keys[key]) return key;
    });
    params.forEach((param) => (data[param] = keys[param]));
    // console.log("data", data);
    const res = await $get("transaction/");
    // console.log(res);
    if (res) return res.data || [];
  };

  const getBalance = async () => {
    const res = await $get("account/get_cash_balance/");
    // console.log("res", res);
    if (res) return res.cash;
  };

  const generateExternalRechargeCode = async (amount: number) => {
    const result = await $post(
      "transaction/generate_external_recharge_code/",
      { amount },
      false
    );
    const { res, ...data } = result;
    if (res === "Success") return data;
    // else return
  };

  const savePapaypalRecharge = async (orderID: string, code: string) => {
    // console.log(orderID, code);
    const res = await $post(
      "transaction/external_recharge_account/",
      { orderID, code },
      false
    );
    // console.log(res);
    return !!res;
  };

  const rechargeWithBankAccount = async (
    bank_account_id: string,
    code: string
  ) => {
    // console.log(bank_account_id, code);
    const res = await $post(
      "transaction/external_recharge_account_ach/",
      { bank_account_id, code },
      false
    );
    // console.log(res);
    return !!res;
  };

  const readInvoiceQrcode = async (code: string) => {
    const res = await $post("transaction/read_invoice_qr/", { code }, false);
    // console.log(res);
    return res;
  };

  const generateInvoice = async (amount: number, description?: string) => {
    const res = await $post(
      "transaction/generate_invoice_qr/",
      { amount, transaction_type: "paiement", description },
      false
    );
    // console.log(res);
    return res;
  };

  const listenInvoiceValidation = async (code: string) => {
    const res = await $post("transaction/check_invoice_qr_status/", { code }, false);
    // console.log(res);
    return res;
  };

  return {
    readQrCode,
    getBalance,
    readPhone,
    readPseudo,
    getReceiver,
    getOperations,
    transfert,
    withdraw,
    readInvoiceQrcode,
    deposit,
    pay,
    payInvoice,
    generateExternalRechargeCode,
    savePapaypalRecharge,
    rechargeWithBankAccount,
    generateInvoice,
    listenInvoiceValidation,
  };
};
