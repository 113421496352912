import React from 'react';
import {UserIcon,BuildingOffice2Icon, BriefcaseIcon} from '@heroicons/react/24/outline'
import AccoutTypeItem from '../../components/AccoutTypeItem';
import { AccountType } from '../../models';
import { useNavigate } from 'react-router-dom';

const AccountTypes = () => {
  const navigate =useNavigate()
  const selectAccountType=(accountType:AccountType)=>{
    window.sessionStorage.setItem('nj_accountType',accountType);
    navigate('/login');
  }
  return (
    <div className='h-full flex flex-col py-8 gap-8'>
     <AccoutTypeItem  Icon={BriefcaseIcon} title="Compte Opérations"  
     onSelectAccountType={()=>selectAccountType(AccountType.OPERATION)}
     />
     <AccoutTypeItem Icon={BuildingOffice2Icon} title="Compte Nation"
       onSelectAccountType={()=>selectAccountType(AccountType.NATION)}/>
     <AccoutTypeItem Icon={UserIcon} title="Compte Support"
       onSelectAccountType={()=>selectAccountType(AccountType.SUPPORT)}/>
    </div>
  )
}

export default AccountTypes
