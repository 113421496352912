import {
  BellIcon,
  ChartBarIcon,
  ChatBubbleLeftRightIcon,
  DocumentCheckIcon,
  HomeIcon,
  QrCodeIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { useAuth, useNotification } from "../store";
import { AccountType, IProfile } from "../models";

const AuthLayout = () => {
  const { getProfile } = useAuth();
  const navigate = useNavigate();
  const [accountType] = useState<AccountType>(
    window.sessionStorage.getItem("nj_accountType") as AccountType
  );
  const [profile, setProfile] = useState<IProfile>();
  const { getFcmToken } = useNotification();

  useEffect(() => {
    const localProfile = getProfile();
    getFcmToken();
    // console.log(profile);
    localProfile ? setProfile(localProfile) : navigate("/");
  }, []);

  return (
    <div className="h-screen w-full">
      <div className="h-[calc(100%-6rem)]  bg-gray-50 w-full">
        {/* layout section */}
        <Outlet />
      </div>
      {accountType === AccountType.SUPPORT ? (
        <div className="bg-light h-24 w-full   shadow-xl rounded-t-2xl  text-gray-600 grid grid-flow-col auto-cols-fr  p-4">
            <NavLink
            to="/dashboard/support"
            className="h-full flex flex-col items-center justify-around text-gray-600 active:text-primary"
          >
            <ChatBubbleLeftRightIcon className="w-[1.5rem]" />
            <h3 className="text-xs leading-none">Discussions</h3>
          </NavLink>
          <NavLink
            to="/dashboard/documents"
            className="h-full flex flex-col items-center justify-around text-gray-600 active:text-primary"
          >
            <DocumentCheckIcon className="w-[1.5rem]" />
            <h3 className="text-xs leading-none">Documents</h3>
          </NavLink>
          <NavLink
            to="/dashboard/notifications"
            className="h-full flex flex-col items-center justify-around text-gray-600 active:text-primary"
          >
            <BellIcon className="w-[1.5rem]" />
            <h3 className="text-xs leading-none">Notifications</h3>
          </NavLink>
          <NavLink
            to="/dashboard/profile"
            className="h-full flex flex-col items-center justify-around text-gray-600 active:text-primary"
          >
            <UserIcon className="w-[1.5rem]" />
            <h3 className="text-xs leading-none">Profil</h3>
          </NavLink>
        </div>
      ) : (
        <div
          className={`${
            accountType === AccountType.OPERATION
              ? "bg-light"
              : accountType === AccountType.NATION
              ? "bg-tertiary"
              : "bg-teal-300"
          } h-24 w-full   shadow-xl rounded-t-2xl  text-gray-600 grid grid-flow-col auto-cols-fr  p-4`}
        >
          <NavLink
            to="/dashboard/home"
            className="h-full flex flex-col items-center justify-around text-gray-600 active:text-primary"
          >
            <HomeIcon className="w-[1.5rem]" />
            <h3 className="text-xs leading-none">Acccueil</h3>
          </NavLink>
          <NavLink
            to="/dashboard/historique"
            className="h-full flex flex-col items-center justify-around text-gray-600 active:text-primary"
          >
            <ChartBarIcon className="w-[1.5rem]" />
            <h3 className="text-xs leading-none">Historique</h3>
          </NavLink>
          {/* <div className="relative -top-12 bg-transprent p-1 pl-2  rounded-full w-16 h-16">
            <NavLink
              to="/dashboard/scan"
              className="h-full bg-tertiary rounded-full text-light  flex flex-col items-center justify-around active:text-primary"
            >
              <QrCodeIcon className="w-[1.5rem]" />
            </NavLink>
          </div> */}

          <NavLink
            to="/dashboard/notifications"
            className="h-full flex flex-col items-center justify-around text-gray-600 active:text-primary"
          >
            <BellIcon className="w-[1.5rem]" />
            <h3 className="text-xs leading-none">Notifications</h3>
          </NavLink>
          <NavLink
            to="/dashboard/profile"
            className="h-full flex flex-col items-center justify-around text-gray-600 active:text-primary"
          >
            <UserIcon className="w-[1.5rem]" />
            <h3 className="text-xs leading-none">Profil</h3>
          </NavLink>
        </div>
      )}
    </div>
  );
};

export default AuthLayout;
