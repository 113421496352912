import {
  ArrowPathIcon,
  Cog6ToothIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import LogoImg from "../../assets/img/logo.png";
import { useOperation } from "../../store";

const Historique = () => {
  const [operations, setOperations] = useState<any[]>([]);
  const [startDate, setStartDate] = useState();

  const [startYear, setStartYear] = useState();
  const [startMonth, setStartMonth] = useState();
  const [startDay, setStartDay] = useState();

  const [endDate, setEndDate] = useState(new Date());

  const { getOperations } = useOperation();

  useEffect(() => {
    getLastOperations();
  }, []);

  const getFormatedDay = (date: Date): any => {
    const dayText =
      date.getDate() < 10 ? "0" + date.getDate() : date.getDate().toString();
    const month = date.getMonth() + 1;
    const monthText = month < 10 ? "0" + month : month.toString();
    const yearText = date.getFullYear().toString();
    const res = { year: yearText, month: monthText, day: dayText };
    console.log(res);

    return res;
  };

  const getLastOperations = async () => {
    const date = new Date();
    const res = getFormatedDay(date);
    const data = await getOperations(res);

    setOperations(data);
  };

  return (
    <div className="h-full w-full overflow-hidden">
      <div className="h-16 w-full bg-white  p-1 flex justify-between items-center">
        <img src={LogoImg} alt="logo" className="w-16 h-16 rounded-full" />
        <button className="rounded-2xl w-12 h-12 flex justify-center items-center border aspect-square">
          <Cog6ToothIcon className="w-8" />
        </button>
      </div>
      <div className="space-y-4 h-[calc(100%-4rem)] overflow-y-auto p-8">
        <h2 className="text-primary text-lg">Mes opérations</h2>
        <div className="w-full flex">
          {/* <div className='w-1/2 border-right text-center '>
          <input type="date" className='p-2 rounded-xl' />
        </div> */}
          {/* <div className='w-1/2 text-center'>
            <input type="date" className='p-2 rounded-xl' />
        </div> */}
          {/* <select name="" id=""></select> */}
        </div>
        <div className="w-full  space-y-4">
          <div className=" w-full grid grid-cols-3 gap-4">
            <div className="p-1">
              <input className="p-1" placeholder="YYYYY" type="number" />
            </div>
            <div className="p-1">
              <input className="p-1" placeholder="MM" type="number" />
            </div>
            <div className="p-1">
              <input className="p-1" placeholder="DD" type="number" />
            </div>
          </div>
          {operations.map((operation) => (
            <div
              key={operation.id}
              className="w-full bg-white shadow-sm p-2 flex items-center justify-between rounded-xl gap-2"
            >
              <div className="p-2 flex gap-2 items-center">
                <ArrowPathIcon className="w-8 h-8 mx-auto text-primary" />
                <div>
                  <h3 className="text-xs text-primary">Transfert sortant</h3>
                  <p className="text-sm text-gray-400">
                    à {operation.receiver_name}
                  </p>
                </div>
              </div>
              <div>
                <p className="text-xs text-gray-500">
                  {operation.created_at} 
                </p>
                <span className="text-sm text-primary">
                  {+operation.amount} F CFA
                </span>
              </div>
            </div>
          ))}
          {/* <div className="w-full bg-white shadow-sm p-2 flex items-center justify-between rounded-xl gap-2">
          <div className="p-2 flex gap-2 items-center">
            <ShoppingCartIcon className="w-8 h-8 mx-auto text-secondary" />
            <div>
              <h3 className="text-xs text-secondary">Paiment</h3>
              <p className="text-sm text-gray-400">chez Opéra</p>
            </div>
          </div>
          <div className="text-right">
            <p className="text-xs text-gray-500">12/01/2023 à 8:00</p>
            <span className="text-sm text-secondary">3 500 F CFA</span>
          </div>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default Historique;
