import React, { useEffect, useState } from "react";
import { AccountType } from "../../models";
import { useNavigate } from "react-router-dom";
import {
  ArrowLeftIcon,
  BanknotesIcon,
  BuildingOffice2Icon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { RecaptchaVerifier } from "firebase/auth";
import { useAuth, useShared } from "../../store";
import { auth } from "../../configs/firebase";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import LoaderImg from "../../assets/img/loader.gif";

const LoginPage = () => {
  const [accountType] = useState<AccountType>(
    window.sessionStorage.getItem("nj_accountType") as AccountType
  );
  const [userId, setUserId] = useState<string>();
  const [pseudo, setPseudo] = useState<string>();
  const [verifier, setVerifier] = useState<any>();
  const { verifyAccount, verifyPhoneNumber } = useAuth();
  const { displayLoader, displayAlert } = useShared();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setUserId(sessionStorage.getItem("nj_userId") as string);
    let verify = new RecaptchaVerifier(
      "recaptcha-container",
      { size: "invisible" },
      auth
    );
    setVerifier(verify);
  }, []);
  const navigate = useNavigate();

  const goBack = () => {
    navigate("/");
  };

  const onSetUserIdhandler = (e: any) => {
    console.log(e.target.value);
    setUserId(e.target.value);
  };

  const onSubmitHandler = async () => {
   
    setLoader(true);
    displayLoader("Connexion...", 5);
    let account;
    if (accountType === AccountType.DISTRIBUTOR) {
      if (!userId) return;
      account = await verifyAccount({
        username: userId,
        account_type: accountType,
      });
      window.sessionStorage.setItem("nj_userId", userId);
    } else {
      if (!pseudo) return;
      account = await verifyAccount({
        username: pseudo,
        account_type: accountType,
      });
      window.sessionStorage.setItem("nj_pseudo", pseudo);
      if (account.res ==='Success') {
        setUserId(account.phone)
        sessionStorage.setItem("nj_userId", account.phone)
      }
    }
    console.log("Account", account)

    let path = "/";
    if (account && account.res === "Success") {
      path += "password";
      return navigate(path);
    }

    if (account && account.res === "failed" && userId) {
      const res = await verifyPhoneNumber(userId, verifier);
      if (!res) return;
      path += "otp";
      return navigate(path);
    }
    window.alert("Une erreur s'est produite. Veuillez réessayer!");
  };

  return (
    <>
      {loader ? (
        <div
          className="absolute  top-0 left-0 right-0 bottom-0  w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
          onClick={($event) => {
            setLoader(false);
          }}
        >
          <div className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8">
            {/* <h2 className="text-lg uppercase font-bold">
              Entrer le montant <br />
            </h2> */}

            <img src={LoaderImg} alt="" />
            <p className="text-gray-500 italic text-sm">Connexion...</p>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="h-full flex flex-col  items-center justify-between py-8 gap-8">
        {accountType === AccountType.OPERATION ? (
          <div className="space-y-8">
            <h1 className="text-blue-200">Compte Opérations</h1>
            <UserIcon className="text-blue-200 animate-bounce" />
          </div>
        ) : accountType === AccountType.NATION ? (
          <div className="space-y-8">
            <h2 className="text-green-100">Compte Nation</h2>
            <BanknotesIcon className="text-blue-200 animate-bounce" />
          </div>
        ) : accountType === AccountType.SUPPORT ? (
          <div className="space-y-8">
            <h2 className="text-purple-100">Compte Support</h2>
            <BuildingOffice2Icon className="text-blue-200 animate-bounce" />
          </div>
        ) : (
          <h2 className="text-red-500">Pas de type de compte</h2>
        )}

        <div className="w-full flex justify-center">
          <button onClick={goBack}>
            {" "}
            <ArrowLeftIcon className="text-white w-8" />
          </button>
        </div>

        <div className="w-[calc(100%+4rem)] h-80 -mb-16 flex flex-col justify-around items-center  bg-light rounded-t-2xl shadow-lg p-8 md:mb-0 md:max-w-lg md:rounded-b-2xl">
          <div className="w-full space-y-8 px-4">
            <h2 className="text-tertiary text-xl font-bold">Connexion</h2>
          { accountType === AccountType.DISTRIBUTOR?     <PhoneInput
              className="w-fulloutline-none border  rounded-l-full rounded-r-full text-center  border-primary p-6 rounded h-10 text-primary text-xl"
              placeholder="Enter phone number"
              value={userId}
              onChange={setUserId}
            />:   <input
            onChange={($event) => setPseudo($event.target.value)}

            type="text"
            placeholder="Entrer le pseudo"
            className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary py-4 rounded h-10 text-primary "
          />
}

       
            {/* <input
            onChange={($event) => onSetUserIdhandler($event)}
            type="tel"
            placeholder="Enter your phone number"
            className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary py-4 rounded h-10 text-primary text-xl"
          /> */}
            <small></small>
          </div>

          <div className="p-4">
            <small></small>
            <button
              disabled={!userId && !pseudo}
              onClick={onSubmitHandler}
              className="bg-secondary px-16 py-2 rounded-l-full rounded-r-full text-dark"
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
