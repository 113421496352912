import { AccountType } from "../models"
import { useApi } from "./api"

export const useNation = () => {
    const { $post, $get, objToArray } = useApi()

    const getNations = async () => {
        const { data } = await $post('account/get_sub_accounts/', { account_type: AccountType.NATION }, false)
        if (data) {
            console.log(data);
            // const result = objToArray(data)
            return data
        }
        return [];
    }

    const addNation = async (data: any) => {
        try {
            const res = await $post('signup_sub_account/', data, false)
            console.log(res);
            return res
        } catch (err) {
            window.alert("Echec d'enregistrement du contact!")
        }
    }

    return { addNation, getNations }

}

function objToArray(data: any) {
    throw new Error("Function not implemented.")
}
