import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon, BuildingOffice2Icon } from "@heroicons/react/24/outline";
import { useAuth, useDistributor } from "../../store";

const Distributors = () => {
  const navigate = useNavigate();
  const { getDistributors } = useDistributor();
  const [distributors, setDistributors] = useState<any[]>([]);
  const [profile, setProfile] = useState<any>();
  const { getProfile } = useAuth();


  useEffect(() => {
    const localProfile = getProfile();
    // console.log(localProfile);
    localProfile ? setProfile(localProfile) : navigate("/");
    loaDistributors(localProfile.name);
  }, []);

  const loaDistributors = async (country: string) => {
    const distributors = await getDistributors(country);
    setDistributors(distributors);
  };

  const goBack = () => {
    navigate("/dashboard/home");
  };

  return (
    <>
     
      <div>
        <div className="h-16 w-full  bg-white p-1 flex justify-between items-center">
        <button onClick={goBack} className="">
          <ArrowLeftIcon className="w-8" />
        </button>
          <h3 className="text-lg">
           Distributeurs
          </h3>
          <div></div>
        </div>

        <div className="space-y-4 p-8">
          <div className="flex justify-between">
            <h2 className="text-primary text-lg">Liste des comptes </h2>
          </div>
          <div className="w-full  space-y-4 h-96">
            {distributors.map((distributor) => (
              <div
                key={distributor.id}
                className="w-full bg-white shadow-sm p-2 flex items-center justify-between rounded-xl gap-2"
              >
                <div className="p-2 flex gap-2 items-center">
                  <BuildingOffice2Icon className="w-8 h-8 mx-auto text-primary" />
                  <div>
                    <h3 className="text-xs text-primary">{distributor.display_name}</h3>
                    <p className="text-sm text-gray-400">{distributor.username}</p>
                  </div>
                </div>
                <div>
                  <p className="text-xs text-gray-500">
                    {distributor.status}
                  </p>
                  <span className="text-sm text-primary">
                    {+distributor.cash_balance} F CFA
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Distributors;
