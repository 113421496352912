import React, { useEffect, useReducer, useState } from "react";
import { AccountType } from "../../models";
import { useNavigate } from "react-router-dom";
import LoaderImg from "../../assets/img/loader.gif";
import {
  ArrowLeftIcon,
  BuildingOffice2Icon,
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { useAuth } from "../../store";
import { useSupport } from "../../store/support";

const Supports = () => {
  const navigate = useNavigate();
  const { getSupports, addSupport } = useSupport();
  const { verifyAccount } = useAuth();
  const [supports, setSupport] = useState<any[]>([]);
  const [displayForm, setDisplayForm] = useState<boolean>(false);
  const [isPseudoValid, setIsPseudoValid] = useState<boolean>(true);
  const [loader, setLoader] = useState(false);
  const [displayResModal, setDisplayResModal] = useState(false);
  const [response, setResponse] = useState<any>();

  const [state, dispatch] = useReducer(
    (state: any, action: any): any => ({
      ...state,
      ...action,
    }),
    {
      pseudo: "",
      last_name: "",
      middle_name: "",
      first_name: "",
      phone: "",
      password: "",
      account_type: AccountType.SUPPORT,
    }
  );

  useEffect(() => {
    loadSupports();
  }, []);

  const loadSupports = async () => {
    const supports = await getSupports();
    console.log(supports);
    setSupport(supports);
  };

  const onAdd = async () => {
    setDisplayForm(true);
  };

  const onSubmit = async () => {
    setLoader(true);
    const data = await addSupport(state);
    setLoader(false);
    console.log(data);
    setResponse(data);
    setDisplayResModal(true);
    loadSupports();
  };

  const verifyPseudo = async (event: any) => {
    // console.log(event.target.value)
    if (event.target.value.length < 4) return setIsPseudoValid(false);
    const req: any = {
      // account_type: AccountType.COMMERCIAL,
      pseudo: event.target.value,
    };
    const { res } = await verifyAccount(req);
    setIsPseudoValid(res !== "Success");
    console.log("result", res);
  };

  const goBack = () => {
    navigate("/dashboard/home");
  };

  return (
    <>
      {displayForm ? (
        <div
          className="absolute  w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
          onClick={($event) => {
            setDisplayForm(false);
          }}
        >
          <div
            onClick={($event) => {
              $event.stopPropagation();
            }}
            className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8 "
          >
            <input
              onChange={($event) =>
                dispatch({ first_name: $event.target.value })
              }
              type="text"
              placeholder="Nom de famille"
              className="w-full py-4  text-black outline-none rounded-l-full rounded-r-full p-2 text-md  border-2 border-primary"
            />
            <input
              onChange={($event) =>
                dispatch({ last_name: $event.target.value })
              }
              type="text"
              placeholder="Prénoms"
              className="w-full  py-4  text-black outline-none rounded-l-full rounded-r-full p-2 text-md border-2 border-primary"
            />
            <div className="w-full space-y-2">
              <input
                onChange={($event) => dispatch({ pseudo: $event.target.value })}
                onKeyUp={verifyPseudo}
                type="text"
                placeholder="Pseudo"
                className="w-full py-4  text-black outline-none rounded-l-full rounded-r-full p-2 text-md  border-2 border-primary"
              />
              {state.pseudo && !isPseudoValid ? (
                <small className="text-red-500 text-sm">Pseudo invalid!</small>
              ) : (
                <></>
              )}
            </div>

            <input
              onChange={($event) => dispatch({ phone: $event.target.value })}
              type="tel"
              placeholder="Contact"
              className="w-full  py-4  text-black outline-none rounded-l-full rounded-r-full p-2 text-md border-2 border-primary"
            />
            <input
              onChange={($event) => dispatch({ password: $event.target.value })}
              type="password"
              placeholder="Mot de passe"
              className="w-full py-4  text-black outline-none rounded-l-full rounded-r-full p-2 text-md  border-2 border-primary"
            />

            <div className="w-full">
              <button
                disabled={
                  !isPseudoValid ||
                  !state.pseudo ||
                  !state.last_name ||
                  !state.first_name ||
                  !state.phone ||
                  !state.password
                }
                className="bg-secondary px-8 py-2 rounded-l-full rounded-r-full text-dark w-full"
                onClick={onSubmit}
              >
                Suivant
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {loader ? (
        <div
          className="absolute  top-0 left-0 right-0 bottom-0  w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
          onClick={($event) => {
            setLoader(false);
          }}
        >
          <div className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8">
            {/* <h2 className="text-lg uppercase font-bold">
              Entrer le montant <br />
            </h2> */}

            <img src={LoaderImg} alt="" />
            <p className="text-gray-500 italic text-sm">Connexion...</p>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* Response */}
      {displayResModal ? (
        <div
          className="absolute  top-0 left-0 right-0 bottom-0 w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
          onClick={($event) => {
            setDisplayResModal(false);
          }}
        >
          <div className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8">
            {/* <h2 className="text-lg uppercase font-bold">
              Entrer le montant <br />
            </h2> */}
            {response?.res === "Success" ? (
              <CheckCircleIcon className="w-32 h-32 text-secondary" />
            ) : (
              <>
                {" "}
                <InformationCircleIcon className="w-32 h-32 text-red-500" />
                <p className="text-red-500 italic text-sm">
                  {response?.message || "Ajouté avec succés"}
                </p>
              </>
            )}
            {/* {response?.res != (
              <div className="flex flex-col gap-4">
                <button onClick={() => { setPassword("") ;setDisplayResModal(false) }} className="bg-cyan-500  py-2 rounded-l-full rounded-r-full h-fit text-dark">
                  Resssayer ({response?.remainingTrials} tentatives restantes)
                </button>
                <button onClick={onRecover}  className="bg-tertiary px-16 py-2 rounded-l-full rounded-r-full h-fit text-dark">
                  Recupérer mon mot passe
                </button>
              </div>
            ) : (
              <></>
            )} */}
          </div>
        </div>
      ) : (
        <></>
      )}

      <div>
        <div className="h-16 w-full  bg-white p-1 flex justify-between items-center">
          <button onClick={goBack} className="">
            <ArrowLeftIcon className="w-8" />
          </button>
          <h3 className="text-lg">Supports</h3>
          <div></div>
        </div>

        <div className="space-y-4 p-8">
          <div className="flex justify-between">
            <h2 className="text-primary text-lg">Liste des comptes</h2>
            <button onClick={onAdd}>Ajouter</button>
          </div>
          <div className="w-full  space-y-4 h-96">
            {supports.map((support) => (
              <div
                key={support.id}
                className="w-full bg-white shadow-sm p-2 flex items-center justify-between rounded-xl gap-2"
              >
                <div className="p-2 flex gap-2 items-center">
                  <BuildingOffice2Icon className="w-8 h-8 mx-auto text-primary" />
                  <div>
                    <h3 className="text-xs text-primary">{support.pseudo}</h3>
                    <p className="text-sm text-gray-400">{support.name}</p>
                  </div>
                </div>
                <div>
                  <p className="text-xs text-gray-500">
                    {support.date} à {support.time}
                  </p>
                  {/* <span className="text-sm text-primary">
                    {+support.cash_balance} F CFA
                  </span> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Supports;
