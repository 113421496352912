import { useApi } from "./api"

export const useDistributor = () => {
    const { $post, objToArray } = useApi()

    const getDistributors = async (country: string) => {
        // console.log(country);
        const { data } = await $post('account/get_distributors/', { country }, false)
        if (data) {
            // console.log(data);
            // const result = objToArray(data)
            return data
        }
        return [];
    }

    // const addDistributor = async (data: any) => {
    //     try {
    //         const res = await $post('signup_sub_account/', data, false)
    //         console.log(res);
    //         return res
    //     } catch (err) {
    //         window.alert("Echec d'enregistrement du contact!")
    //     }
    // }

    return { getDistributors }

}

