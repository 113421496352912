import React from 'react'
import { AccountType } from '../models';
type props={
  Icon:any;
  title:string;
  onSelectAccountType:()=>void
}

const AccoutTypeItem = ({Icon,title,onSelectAccountType}:props) => {
  return (
    <div onClick={onSelectAccountType} className='w-full h-full max-w-lg mx-auto bg-white shadow-lg border border-primary rounded-xl flex flex-col justify-between p-8' >
        <Icon
        className='h-12 text-primary'
        />
        <p className=''>{title}</p>
    </div>
  )
}

export default AccoutTypeItem